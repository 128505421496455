import React, { Component } from 'react'
import BlockstackContext from 'react-blockstack/dist/context'
import { BlockstackButton } from 'react-blockstack-button'

// Demonstrating BlockstackContext in support of legacy React Class Components

export default class Landing extends Component {
  static contextType = BlockstackContext
  render () {
    const { signIn } = this.context
    return (
      <div className="panel-landing text-center mt-5">
        <h1 className="landing-heading">Wish List</h1>
        <p className="lead">
          Sign in for your secret wish list for the holidays.
        </p>
        <p className="lead">
          Private &amp; encrypted. Keep the Grinch from getting to know your wishes!
        </p>
        <p className="lead">
          <BlockstackButton onClick={ signIn }/>
        </p>
        <p hidden="true">
          <a href=" https://app.netlify.com/start/deploy?repository=https://github.com/REBL-Stack/starter-app">
            <img src="https://www.netlify.com/img/deploy/button.svg" alt="deploy"/>
          </a>
        </p>
      </div>
    )
  }
}
