import React, {useRef, useState, useEffect} from 'react'
import { useFile, useFilesList } from 'react-blockstack'

const avatarFallbackImage = 'https://s3.amazonaws.com/onename/avatar-placeholder.png';

function Profile ({ person }) {
  return (
   <div className="Profile">
    <div className="avatar-section">
      <img src={ (person && person.avatarUrl()) || avatarFallbackImage }
           className="img-rounded avatar" id="avatar-image" alt="Avatar"/>
    </div>
    <h1>Hello, <span id="heading-name">{ (person && person.name()) || 'Nameless Person' }</span>!</h1>
   </div>
  )
}

function NoteField ({title, path, placeholder}) {
  const [note, setNote] = useFile(path)
  const textfield = useRef()
  const spinner = useRef()
  const saveAction = () => {
    spinner.current.classList.remove('d-none')
    setNote(textfield.current.value)
    setTimeout(() => spinner.current.classList.add('d-none'), 1500)
  }
  return(
    <div className="NoteField input-group ">
      <div className="input-group-prepend">
        <span className="input-group-text">{title}</span>
      </div>
      <input type="text" ref={textfield} className="form-control" disabled={note === undefined}
             defaultValue={ note || ""} placeholder={placeholder}
             onKeyUp={(e) => {if (e.key === "Enter") saveAction()}}/>
      <div className="input-group-append">
        <button className="btn btn-outline-secondary" type="button"
                disabled={!setNote} onClick={saveAction}>
          <div ref={spinner} role="status"
               className="d-none spinner-border spinner-border-sm text-info align-text-top mr-2"/>
          Save
        </button>
      </div>
    </div>
  )
}

function Notes (props) {

}

export default function Main ({ person }) {
  const root = "note/"
  const [files] = useFilesList()
  const [notes, setNotes] = useState()
  useEffect(() => {
    if (files) {
      const items = files.filter((name) => name && name.startsWith && name.startsWith(root))
      setNotes(items.length > 0 ? items : [root+"1"])
    }
  }, [files])
  console.log("Notes:", notes)
  return (
    <main className="panel-welcome">
      <div className="text-center">
        <Profile person={person}/>
      </div>
      <div className="text-center">
        What do you wish for? Your secret is safe with us... and Santa.
      </div>
      <div className="lead row mt-5">
        <div className="mx-auto col-md-8 col-lg-6 px-4">
          {notes && notes.map((name) =>
             <NoteField key={name} title="Wish" path={name} placeholder="what you want for the holidays"/>)}
          <button className="plus btn btn-outline-primary btn-lg rounded-circle mr-2"
                  onClick={() => setNotes((notes) => [...notes, root + Date.now()])}>
            +
          </button>
        </div>
      </div>
    </main>
  )
}
